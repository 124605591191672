// extracted by mini-css-extract-plugin
export var background = "by_x";
export var backgroundContainer = "by_fl";
export var bottomWave = "by_cx";
export var buttons = "by_cb";
export var container = "by_b";
export var content = "by_bZ";
export var icon = "by_bv";
export var iconContainer = "by_bX";
export var middleWave = "by_cw";
export var negativeTop = "by_gt";
export var overlap = "by_gw";
export var section = "by_d";
export var selected = "by_bg";
export var tab = "by_dP";
export var tabText = "by_dN";
export var title = "by_h";
export var topWave = "by_gv";