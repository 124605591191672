// extracted by mini-css-extract-plugin
export var container = "K_b";
export var content = "K_bZ";
export var icon = "K_bv";
export var iconContainer = "K_bX";
export var icons = "K_bL";
export var image = "K_g";
export var label = "K_cf";
export var section = "K_d";
export var text = "K_b7";
export var title = "K_h";