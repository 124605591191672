// extracted by mini-css-extract-plugin
export var bottom = "bp_s";
export var contact = "bp_fz";
export var faqCat = "bp_fJ";
export var faqCatDescription = "bp_fM";
export var faqCatTitle = "bp_fL";
export var faqCategories = "bp_fH";
export var faqIcon = "bp_fK";
export var footerText = "bp_cy";
export var icon = "bp_bv";
export var iconContainer = "bp_bX";
export var iconText = "bp_fC";
export var immediate = "bp_fB";
export var inputContainer = "bp_cS";
export var linksSubtitle = "bp_fF";
export var linksTitle = "bp_fD";
export var search = "bp_cR";
export var searchInput = "bp_cT";
export var section = "bp_d";
export var selected = "bp_bg";
export var supportButton = "bp_fN";
export var supportLinks = "bp_fG";
export var top = "bp_l";
export var wave = "bp_fy";