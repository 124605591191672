// extracted by mini-css-extract-plugin
export var buttons = "bz_cb";
export var left = "bz_bH";
export var media = "bz_gz";
export var right = "bz_bQ";
export var section = "bz_d";
export var selected = "bz_bg";
export var signature = "bz_gy";
export var testimonial = "bz_gx";
export var text = "bz_b7";
export var textContainer = "bz_bV";
export var video = "bz_gk";