// extracted by mini-css-extract-plugin
export var caption = "bw_gj";
export var grey = "bw_dG";
export var heading = "bw_G";
export var image = "bw_g";
export var left = "bw_bH";
export var reverse = "bw_dC";
export var right = "bw_bQ";
export var section = "bw_d";
export var video = "bw_gk";
export var videoContainer = "bw_b4";
export var videoWrapper = "bw_b5";