// extracted by mini-css-extract-plugin
export var active = "bs_bd";
export var categories = "bs_cP";
export var category = "bs_cQ";
export var icon = "bs_bv";
export var icons = "bs_bL";
export var image = "bs_g";
export var member = "bs_fT";
export var memberTitle = "bs_fW";
export var name = "bs_fV";
export var placeholder = "bs_bb";
export var section = "bs_d";
export var show = "bs_L";
export var subtitle = "bs_cc";
export var team = "bs_fS";