// extracted by mini-css-extract-plugin
export var active = "bc_bd";
export var arrow = "bc_9";
export var button = "bc_cs";
export var buttonMobile = "bc_d5";
export var column = "bc_dj";
export var columns = "bc_db";
export var container = "bc_b";
export var description = "bc_c9";
export var disclaimer = "bc_cr";
export var disclaimerButton = "bc_d1";
export var disclaimerContainer = "bc_d7";
export var disclaimerText = "bc_fb";
export var fadeBottom = "bc_d9";
export var fadeTop = "bc_d8";
export var featureFooter = "bc_d3";
export var footer = "bc_cM";
export var footerMobile = "bc_d4";
export var heading = "bc_G";
export var highlight = "bc_d2";
export var icon = "bc_bv";
export var iconContainer = "bc_bX";
export var left = "bc_bH";
export var padding = "bc_ds";
export var popular = "bc_dr";
export var prefix = "bc_d0";
export var recommend = "bc_dY";
export var recommended = "bc_dZ";
export var right = "bc_bQ";
export var section = "bc_d";
export var show = "bc_L";
export var subHeading = "bc_H";
export var suffix = "bc_dp";
export var title = "bc_h";
export var waveContainer = "bc_d6";
export var waveOne = "bc_q";
export var waveThree = "bc_cB";
export var waveTwo = "bc_n";
export var waves = "bc_cz";