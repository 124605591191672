// extracted by mini-css-extract-plugin
export var bottomContainer = "x_b9";
export var buttons = "x_cb";
export var icon = "x_bv";
export var link = "x_bt";
export var section = "x_d";
export var social = "x_b8";
export var text = "x_b7";
export var title = "x_h";
export var topContainer = "x_b6";
export var videoContainer = "x_b4";
export var videoWrapper = "x_b5";