// extracted by mini-css-extract-plugin
export var container = "bC_b";
export var feature = "bC_cJ";
export var featureText = "bC_cK";
export var features = "bC_cG";
export var footer = "bC_cM";
export var footerText = "bC_cy";
export var icon = "bC_bv";
export var section = "bC_d";
export var text = "bC_b7";
export var title = "bC_h";
export var waveOne = "bC_q";
export var waveThree = "bC_cB";
export var waveTwo = "bC_n";
export var waves = "bC_cz";