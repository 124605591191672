// extracted by mini-css-extract-plugin
export var bottomWave = "bk_cx";
export var button = "bk_cs";
export var buttonContainer = "bk_N";
export var buttonFooter = "bk_fp";
export var container = "bk_b";
export var middleWave = "bk_cw";
export var section = "bk_d";
export var subtitle = "bk_cc";
export var textContainer = "bk_bV";
export var title = "bk_h";