// extracted by mini-css-extract-plugin
export var buttons = "Z_cb";
export var container = "Z_b";
export var content = "Z_bZ";
export var icon = "Z_bv";
export var iconContainer = "Z_bX";
export var image = "Z_g";
export var section = "Z_d";
export var selected = "Z_bg";
export var tab = "Z_dP";
export var tabText = "Z_dN";