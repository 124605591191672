// extracted by mini-css-extract-plugin
export var buttons = "M_cb";
export var column = "M_dj";
export var columns = "M_db";
export var container = "M_b";
export var down = "M_dw";
export var download = "M_dv";
export var footer = "M_cM";
export var information = "M_dt";
export var section = "M_d";
export var show = "M_L";
export var text = "M_b7";
export var title = "M_h";
export var units = "M_dx";
export var up = "M_dz";
export var upload = "M_dy";
export var waveOne = "M_q";
export var waveThree = "M_cB";
export var waveTwo = "M_n";
export var waves = "M_cz";