// extracted by mini-css-extract-plugin
export var answer = "bx_gs";
export var arrow = "bx_9";
export var body = "bx_c";
export var category = "bx_cQ";
export var contactIcons = "bx_gl";
export var current = "bx_fs";
export var fade = "bx_gp";
export var footer = "bx_cM";
export var footerText = "bx_cy";
export var icon = "bx_bv";
export var iconContainer = "bx_bX";
export var issue = "bx_gr";
export var issueContainer = "bx_gn";
export var issueText = "bx_gq";
export var issues = "bx_gm";
export var left = "bx_bH";
export var padding = "bx_ds";
export var right = "bx_bQ";
export var section = "bx_d";
export var selectors = "bx_dQ";
export var subtitle = "bx_cc";
export var title = "bx_h";