// extracted by mini-css-extract-plugin
export var active = "B_bd";
export var activeTargetContainer = "B_cF";
export var container = "B_b";
export var content = "B_bZ";
export var contentButton = "B_cL";
export var feature = "B_cJ";
export var featureContainer = "B_cH";
export var featureText = "B_cK";
export var features = "B_cG";
export var footer = "B_cM";
export var footerText = "B_cy";
export var icon = "B_bv";
export var outerFeaturesContainer = "B_cD";
export var section = "B_d";
export var text = "B_b7";
export var title = "B_h";
export var topButton = "B_cC";
export var waveOne = "B_q";
export var waveThree = "B_cB";
export var waveTwo = "B_n";
export var waves = "B_cz";