// extracted by mini-css-extract-plugin
export var button = "V_cs";
export var categories = "V_cP";
export var footerText = "V_cy";
export var input = "V_cZ";
export var map = "V_c3";
export var mapContainer = "V_c2";
export var offered = "V_c0";
export var search = "V_cR";
export var section = "V_d";
export var title = "V_h";