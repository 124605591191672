// extracted by mini-css-extract-plugin
export var appStores = "N_dF";
export var grey = "N_dG";
export var icon = "N_bv";
export var iconGroup = "N_dD";
export var image = "N_g";
export var left = "N_bH";
export var reverse = "N_dC";
export var right = "N_bQ";
export var section = "N_d";
export var textSection = "N_dB";