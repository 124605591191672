// extracted by mini-css-extract-plugin
export var body = "bM_c";
export var button = "bM_cs";
export var close = "bM_F";
export var container = "bM_b";
export var content = "bM_bZ";
export var heading = "bM_G";
export var link = "bM_bt";
export var mobileHide = "bM_hb";
export var open = "bM_bf";
export var section = "bM_d";
export var show = "bM_L";
export var title = "bM_h";