// extracted by mini-css-extract-plugin
export var bottomCircle = "bH_g4";
export var content = "bH_bZ";
export var description = "bH_c9";
export var hide = "bH_z";
export var icon = "bH_bv";
export var image = "bH_g";
export var imageContainer = "bH_g8";
export var left = "bH_bH";
export var nextYear = "bH_g7";
export var right = "bH_bQ";
export var section = "bH_d";
export var shrink = "bH_g6";
export var title = "bH_h";
export var topCircle = "bH_g3";
export var wave = "bH_fy";
export var waveContainer = "bH_d6";
export var year = "bH_g5";