// extracted by mini-css-extract-plugin
export var buttons = "bh_cb";
export var container = "bh_b";
export var image = "bh_g";
export var innerText = "bh_fm";
export var logo = "bh_bn";
export var p = "bh_dL";
export var section = "bh_d";
export var short = "bh_dM";
export var tall = "bh_fn";
export var textContainer = "bh_bV";