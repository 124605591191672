// extracted by mini-css-extract-plugin
export var background = "bq_x";
export var backgroundContainer = "bq_fl";
export var bottomImage = "bq_t";
export var bottomImageContainer = "bq_fR";
export var buttons = "bq_cb";
export var container = "bq_b";
export var imagesContainer = "bq_fQ";
export var middleWave = "bq_cw";
export var section = "bq_d";
export var text = "bq_b7";
export var textContainer = "bq_bV";
export var title = "bq_h";
export var topImage = "bq_m";
export var waveOverlay = "bq_fP";