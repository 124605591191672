// extracted by mini-css-extract-plugin
export var arrow = "bb_9";
export var body = "bb_c";
export var circle = "bb_dW";
export var customer = "bb_dV";
export var feedback = "bb_dT";
export var feedbacks = "bb_dR";
export var footer = "bb_cM";
export var footerText = "bb_cy";
export var left = "bb_bH";
export var nameLocation = "bb_dX";
export var padding = "bb_ds";
export var right = "bb_bQ";
export var section = "bb_d";
export var selected = "bb_bg";
export var selectors = "bb_dQ";
export var subtitle = "bb_cc";
export var text = "bb_b7";
export var title = "bb_h";
export var wrapper = "bb_dS";