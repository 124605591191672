// extracted by mini-css-extract-plugin
export var arrow = "bv_9";
export var background = "bv_x";
export var backgroundContainer = "bv_fl";
export var buttonContainer = "bv_N";
export var categoryOne = "bv_f4";
export var categoryTwo = "bv_f5";
export var container = "bv_b";
export var count = "bv_gd";
export var current = "bv_fs";
export var disclaimer = "bv_cr";
export var gig = "bv_gg";
export var heading = "bv_G";
export var hidden = "bv_W";
export var icon = "bv_bv";
export var left = "bv_bH";
export var location = "bv_c6";
export var locations = "bv_f6";
export var locationsMobile = "bv_fZ";
export var mobileSelect = "bv_gh";
export var on = "bv_f3";
export var overlay = "bv_bT";
export var plus = "bv_gf";
export var right = "bv_bQ";
export var section = "bv_d";
export var selected = "bv_bg";
export var show = "bv_L";
export var speed = "bv_gb";
export var speedContainer = "bv_f9";
export var speedType = "bv_gc";
export var speeds = "bv_f8";
export var speedsUpTo = "bv_f7";
export var subHeading = "bv_H";
export var switcher = "bv_f1";
export var toggle = "bv_f2";
export var toggleContainer = "bv_f0";
export var waveContainer = "bv_d6";
export var waveOne = "bv_q";
export var waveThree = "bv_cB";
export var waveTwo = "bv_n";
export var waves = "bv_cz";
export var white = "bv_fx";